import React from 'react';
import { TabList, TabPanels, Tabs, Tab, TabPanel } from '@chakra-ui/react';
import EnrolmentsTableTemplate from '../../components/Templates/EnrollmentsTableTemplate';

const EnrolmentsTable: React.FC = () => {
  return (
    <Tabs isFitted variant="enclosed" defaultIndex={1}>
      <TabList>
        <Tab>2021</Tab>
        <Tab>2022</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <EnrolmentsTableTemplate year="2021" />
        </TabPanel>
        <TabPanel>
          <EnrolmentsTableTemplate year="2022" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default EnrolmentsTable;
