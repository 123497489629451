import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './hooks/auth';
import Routes from './routes';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

const App: React.FC = () => (
  <Router>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ChakraProvider>

    <GlobalStyles />
  </Router>
);

export default App;
