import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;

export const StudentsList = styled.ul`
  list-style: none;
  width: 100%;
  max-width: 800px;
  text-align: center;
  max-width: 740px;

  li {
    padding: 12px 24px;
    transition: background-color 0.15s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;

    &:hover {
      background-color: #ffcf00;
    }
  }

  svg {
    cursor: pointer;
    transition: transform 0.15s linear;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 100;
`;

export const Popup = styled.div`
  width: 100%;
  max-width: 420px;
  z-index: 100;
  background-color: #f7f6fc;
  padding: 24px;
  border-radius: 8px;

  h2 {
    color: #212529;
    margin-bottom: 24px;
    font-weight: 700;
  }

  p {
    margin-bottom: 24px;

    strong {
      color: #f44336;
      font-weight: 700;
    }

    b {
      font-weight: 700;
      color: #212529;
    }
  }

  button {
    margin-top: 24px;
  }
`;

export const Filter = styled.div`
  position: fixed;
  z-index: 90;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
`;

export const ButtonGroup = styled.div`
  button {
    & + button {
      margin-left: 24px;
    }
  }
`;

export const SearchBar = styled.div`
  margin: 24px 0;
  width: 100%;
  max-width: 740px;

  form {
    display: flex;
    width: 100%;

    button {
      margin-left: 12px;
    }
  }
`;
