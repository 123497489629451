import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Container } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import Heading from '../../components/Heading';
import IconBar from '../../components/IconBar';
import IGradeNames from '../../utils/IGradeNames';
import api from '../../services/api';

interface IValue {
  _id: string;
  grade_name: IGradeNames;
  value: number;
}

interface IFormData {
  maternal: string;
  first_period: string;
  second_period: string;
  first_year: string;
  second_year: string;
  third_year: string;
  fourth_year: string;
  fifth_year: string;
  sixth_year: string;
  seventh_year: string;
  eighth_year: string;
  nineth_year: string;
}

const UpdateValues: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    api
      .get('/values')
      .then(response => {
        const values = response.data as IValue[];

        values.forEach(value => {
          formRef.current?.setFieldValue(value.grade_name, value.value);
        });
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, []);

  const handleUpdateValue = useCallback(
    async ({
      maternal,
      first_period,
      second_period,
      first_year,
      second_year,
      third_year,
      fourth_year,
      fifth_year,
      sixth_year,
      seventh_year,
      eighth_year,
      nineth_year,
    }: IFormData) => {
      try {
        setLoadingButton(true);

        await api.put('/values/maternal', { new_value: maternal });
        await api.put('/values/first_period', { new_value: first_period });
        await api.put('/values/second_period', { new_value: second_period });
        await api.put('/values/first_year', { new_value: first_year });
        await api.put('/values/second_year', { new_value: second_year });
        await api.put('/values/third_year', { new_value: third_year });
        await api.put('/values/fourth_year', { new_value: fourth_year });
        await api.put('/values/fifth_year', { new_value: fifth_year });
        await api.put('/values/sixth_year', { new_value: sixth_year });
        await api.put('/values/seventh_year', { new_value: seventh_year });
        await api.put('/values/eighth_year', { new_value: eighth_year });
        await api.put('/values/nineth_year', { new_value: nineth_year });

        toast.success('Valores atualizados com sucesso!');
      } catch (err) {
        if (err.response) {
          toast.error(`Erro ao atualizar valores: ${err.response.data}`);
        } else {
          toast.error('Erro ao atualizar valores!');
        }
      } finally {
        setLoadingButton(false);
      }
    },
    [],
  );

  return (
    <Container>
      <Loading show={loadingPage} />

      <IconBar />

      <Heading title="Atualizar valores de matrículas" />

      <Form ref={formRef} onSubmit={handleUpdateValue}>
        <div>
          <Input name="maternal" type="number" label="Maternal" />
          <Input name="first_period" type="number" label="Primeiro Período" />
        </div>
        <div>
          <Input name="second_period" type="number" label="Segundo Período" />
          <Input name="first_year" type="number" label="1° Ano" />
        </div>
        <div>
          <Input name="second_year" type="number" label="2° Ano" />
          <Input name="third_year" type="number" label="3° Ano" />
        </div>
        <div>
          <Input name="fourth_year" type="number" label="4° Ano" />
          <Input name="fifth_year" type="number" label="5° Ano" />
        </div>
        <div>
          <Input name="sixth_year" type="number" label="6° Ano" />
          <Input name="seventh_year" type="number" label="7° Ano" />
        </div>
        <div>
          <Input name="eighth_year" type="number" label="8° Ano" />
          <Input name="nineth_year" type="number" label="9° Ano" />
        </div>
        <Button loading={loadingButton} type="submit">
          Atualizar valores
        </Button>
      </Form>
    </Container>
  );
};

export default UpdateValues;
