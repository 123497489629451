import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { Container, GradesList } from './styles';
import IconBar from '../../components/IconBar';

const Grades: React.FC = () => (
  <Container>
    <IconBar hideGoBack hideGoHome />

    <h1>Turmas</h1>

    <strong>Selecione uma turma</strong>

    <Tabs isFitted variant="enclosed" defaultIndex={1}>
      <TabList>
        <Tab>2021</Tab>
        <Tab>2022</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <GradesList>
            <Link to="/students/2021/maternal">Maternal - 2021</Link>
            <Link to="/students/2021/first_period">1º Período - 2021</Link>
            <Link to="/students/2021/second_period">2º Período - 2021</Link>
            <Link to="/students/2021/first_year">1º ano - 2021</Link>
            <Link to="/students/2021/second_year">2º ano - 2021</Link>
            <Link to="/students/2021/third_year">3º ano - 2021</Link>
            <Link to="/students/2021/fourth_year">4º ano - 2021</Link>
            <Link to="/students/2021/fifth_year">5º ano - 2021</Link>
            <Link to="/students/2021/sixth_year">6º ano - 2021</Link>
            <Link to="/students/2021/seventh_year">7º ano - 2021</Link>
            <Link to="/students/2021/eighth_year">8º ano - 2021</Link>
            <Link to="/students/2021/nineth_year">9º ano - 2021</Link>
          </GradesList>
        </TabPanel>
        <TabPanel>
          <GradesList>
            <Link to="/students/2022/maternal">Maternal - 2022</Link>
            <Link to="/students/2022/first_period">1º Período - 2022</Link>
            <Link to="/students/2022/second_period">2º Período - 2022</Link>
            <Link to="/students/2022/first_year">1º ano - 2022</Link>
            <Link to="/students/2022/second_year">2º ano - 2022</Link>
            <Link to="/students/2022/third_year">3º ano - 2022</Link>
            <Link to="/students/2022/fourth_year">4º ano - 2022</Link>
            <Link to="/students/2022/fifth_year">5º ano - 2022</Link>
            <Link to="/students/2022/sixth_year">6º ano - 2022</Link>
            <Link to="/students/2022/seventh_year">7º ano - 2022</Link>
            <Link to="/students/2022/eighth_year">8º ano - 2022</Link>
            <Link to="/students/2022/nineth_year">9º ano - 2022</Link>
          </GradesList>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Container>
);

export default Grades;
