import React from 'react';

import { TabList, TabPanel, TabPanels, Tabs, Tab } from '@chakra-ui/react';
import TaxEnrollmentsTableTemplate from '../../components/Templates/TaxEnrollmentsTableTemplate';

const TaxEnrollmentsTable: React.FC = () => {
  return (
    <Tabs isFitted variant="enclosed" defaultIndex={1}>
      <TabList>
        <Tab>2021</Tab>
        <Tab>2022</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TaxEnrollmentsTableTemplate year="2021" />
        </TabPanel>
        <TabPanel>
          <TaxEnrollmentsTableTemplate year="2022" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TaxEnrollmentsTable;
