import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiXOctagon, FiLock, FiSearch } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import {
  Container,
  StudentsList,
  PopupContainer,
  Popup,
  Filter,
  ButtonGroup,
  SearchBar,
} from './styles';
import Input from '../../Input';
import Button from '../../Button';
import Loading from '../../Loading';
import IconBar from '../../IconBar';
import Heading from '../../Heading';
import IReenrollmentDTO from '../../../dtos/IReenrollmentDTO';
import { formatGrade } from '../../../utils/formatFunctions';
import api from '../../../services/api';

interface IProps {
  year: string;
}

const DeleteEnrollmentsTemplate: React.FC<IProps> = ({ year }) => {
  const formRef = useRef<FormHandles>(null);
  const searchFormRef = useRef<FormHandles>(null);
  const [reenrollments, setReenrollments] = useState<IReenrollmentDTO[]>([]);
  const [searcheds, setSearcheds] = useState<IReenrollmentDTO[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [unlockButton, setUnlockButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedReenrollment, setSelectedReenrollment] = useState<
    IReenrollmentDTO
  >({} as IReenrollmentDTO);

  useEffect(() => {
    setLoadingPage(true);

    api
      .get('/reenrollments', { params: { enrollment_year: year } })
      .then(response => {
        const reenrollmentsFromAPI = response.data as IReenrollmentDTO[];

        setReenrollments(reenrollmentsFromAPI);
      })
      .catch(() => {
        toast.error('Erro ao carregar alunos!');
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, [year]);

  const handleSelectReenrollment = useCallback(
    (reenrollment: IReenrollmentDTO) => {
      setSelectedReenrollment(reenrollment);
      setShowPopup(true);
    },
    [],
  );

  const handleDeleteReenrollment = useCallback(async () => {
    try {
      setLoadingButton(true);

      const { enrollment_number } = selectedReenrollment;

      await api.delete(`/reenrollments/${enrollment_number}`);

      const reenrollmentsWithoutDeleted = reenrollments.filter(
        reenrollment => reenrollment.enrollment_number !== enrollment_number,
      );

      const searchedsWithoutDeleted = searcheds.filter(
        searched => searched.enrollment_number !== enrollment_number,
      );

      setReenrollments(reenrollmentsWithoutDeleted);
      setSearcheds(searchedsWithoutDeleted);
    } catch {
      toast.error('Erro ao deletar aluno!');
    } finally {
      setLoadingButton(false);
      setUnlockButton(false);
      setShowPopup(false);
      setSelectedReenrollment({} as IReenrollmentDTO);
    }
  }, [selectedReenrollment, reenrollments, searcheds]);

  const handleClosePopup = useCallback(() => {
    setShowPopup(false);
    setSelectedReenrollment({} as IReenrollmentDTO);
    setUnlockButton(false);
  }, []);

  const handleSearchStudents = useCallback(
    ({ search }: { search: string }) => {
      const matchReenrollments = reenrollments.filter(reenrollment =>
        reenrollment.student_name.toLowerCase().includes(search.toLowerCase()),
      );

      setSearcheds(matchReenrollments);

      searchFormRef.current?.reset();

      if (matchReenrollments.length <= 0) {
        toast.info('Nenhum aluno encontrado');
      }
    },
    [reenrollments],
  );

  return (
    <Container>
      <Loading show={loadingPage} />

      <IconBar />

      <Heading title="Deletar matrículas" />

      <SearchBar>
        <Form ref={searchFormRef} onSubmit={handleSearchStudents}>
          <Input name="search" placeholder="Nome do aluno" />
          <Button type="submit">
            <FiSearch size={20} />
          </Button>
        </Form>
      </SearchBar>

      <StudentsList>
        {searcheds.length <= 0 &&
          reenrollments.map(reenrollment => (
            <li key={reenrollment.enrollment_number}>
              <div>
                {`${reenrollment.student_name} - ${formatGrade(
                  reenrollment.grade_name,
                )}`}
              </div>

              <FiXOctagon
                onClick={() => handleSelectReenrollment(reenrollment)}
                size={24}
                color="#f44336"
              />
            </li>
          ))}
        {searcheds.length > 0 &&
          searcheds.map(reenrollment => (
            <li key={reenrollment.enrollment_number}>
              <div>
                {`${reenrollment.student_name} - ${formatGrade(
                  reenrollment.grade_name,
                )}`}
              </div>

              <FiXOctagon
                onClick={() => handleSelectReenrollment(reenrollment)}
                size={24}
                color="#f44336"
              />
            </li>
          ))}
      </StudentsList>

      {showPopup && (
        <>
          <PopupContainer>
            <Popup>
              <Form ref={formRef} onSubmit={handleDeleteReenrollment}>
                <h2>
                  {`Deletar ${
                    selectedReenrollment.student_name
                  } da turma ${formatGrade(selectedReenrollment.grade_name)}`}
                </h2>

                <p>
                  <strong>ATENÇÃO:</strong> esta ação não pode ser desfeita!
                  Digite <b>CONFIRMAR</b> para deletar o aluno permanentemente.
                </p>

                <Input
                  name="confirm_message"
                  icon={FiLock}
                  placeholder="CONFIRMAR"
                  onChange={e =>
                    setUnlockButton(e.target.value === 'CONFIRMAR')
                  }
                />

                <ButtonGroup>
                  {unlockButton && (
                    <Button
                      backgroundColor="#f44336"
                      type="submit"
                      loading={loadingButton}
                    >
                      DELETAR
                    </Button>
                  )}
                  <Button type="button" onClick={handleClosePopup}>
                    CANCELAR
                  </Button>
                </ButtonGroup>
              </Form>
            </Popup>
          </PopupContainer>

          <Filter />
        </>
      )}
    </Container>
  );
};

export default DeleteEnrollmentsTemplate;
