import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import DashboardTemplate from '../../components/Templates/DashboardTemplate';

const Dashboard: React.FC = () => (
  <Tabs isFitted variant="enclosed" defaultIndex={1}>
    <TabList>
      <Tab>2021</Tab>
      <Tab>2022</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <DashboardTemplate year="2021" />
      </TabPanel>
      <TabPanel>
        <DashboardTemplate year="2022" />
      </TabPanel>
    </TabPanels>
  </Tabs>
);

export default Dashboard;
