import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;

  form {
    width: 100%;
    max-width: 740px;

    > div {
      display: flex;

      div {
        & + div {
          margin-left: 12px;
        }
      }

      & + div {
        margin-top: 12px;
      }
    }

    button {
      margin-top: 12px;
    }
  }
`;
